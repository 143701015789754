export const useSeoStore = defineStore({
  id: 'seo-store',
  state: () => {
    return {
      seo: {}
    }
  },
  actions: {
    setSeoData(data) {
      this.seo = data
    }
  },
  getters: {
    seoData: state => state?.seo
  }
})
